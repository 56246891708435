import React, {useEffect, useState} from 'react'
import Header from "./header";
import Footer from "./footer";
import { API_ENDPOINT } from '../util/constants';
import siteContext from './siteContext';

import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.slim";
import "../styles/globals.css";


const StaticLayout = ({ pageTitle, children }) => {
  const [siteSettings, setSiteSettings] = useState ({});
  useEffect (()=>{
    fetch(API_ENDPOINT).then(res=>res.json()).then(res=>{ setSiteSettings(res)})
  }, [])

  return (
      <siteContext.Provider value={siteSettings} >
      {siteSettings.menus && <Header menus={siteSettings.menus} logopath={siteSettings.logo} />}
      <div className="wrapper">
        <div className="page__container">
          <div className="zee__cinema container">
            {siteSettings.languageLogo && <img
              src={siteSettings.languageLogo}
              alt="zee_cinema_logo" width="76"
            />}
          </div>
          <div class="event-form-section container">{children}</div>
        </div>
      </div>
      {siteSettings.menus && <Footer menus={siteSettings.menus}/>}
    </siteContext.Provider>
  );
};

export default StaticLayout;
