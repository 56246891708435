import React from "react";

function MetaDetails({meta}) {
  return (
    <>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
    </>
  );
}

export default MetaDetails;
