import React, {useState, useEffect} from "react";
import Layout from '../components/staticLayout';
import ComponentLoader from '../components/componentLoader';
import MetaDetails from "../components/meta";
import { PAGES_ENDPOINT, SITENAME } from '../util/constants';

const AboutUsPage = () => {
  const [pageDetails, setpageDetails] = useState (null);
  useEffect (()=> {
    fetch(PAGES_ENDPOINT + 'about').then(res=>res.json()).then(res=>{ setpageDetails(res) })
  }, [])
  return (
    <Layout >
      {pageDetails && <ComponentLoader comp={pageDetails.data.buckets} />}
    </Layout>
  )
}

export default AboutUsPage
export const Head = () => ( <MetaDetails meta={{
  "title": `About Us - ${SITENAME}`,
  "description": `${SITENAME} About Us description`,
}}/>)
